import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaPhone, FaEnvelope } from 'react-icons/fa';
import { useIntl, FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"

const Contacts = () => {
  const intl = useIntl()
  return (
    <Layout contentType="internal">
      <SEO title={intl.formatMessage({ id: "contacts.title" })}/>
      <div className="container page-content">
        <div className="row">
          <div className="col-md-4 offset-md-1">
            <h1><FormattedMessage id="contacts.title" /></h1>
            <FormattedHTMLMessage id="contacts.desc" />
            <p className="contact-item"><FaPhone/> <span className="medium-text">(+351) 215 894 595</span></p>
            <p className="contact-item"><FaEnvelope/> <span className="medium-text"><a
              href="admin@veks.net">admin@veks.net</a></span></p>
            <h6><FormattedMessage id="contacts.support" />:</h6>
            <p className="contact-item"><FaPhone/> (+351) 215 894 595</p>
            <p className="contact-item"><FaPhone/> (+351) 215 894 595</p>
            <p className="contact-item"><FaPhone/> (+351) 215 894 595</p>
            <p className="contact-item"><FaPhone/> (+351) 215 894 595</p>
            <p className="contact-item"><FaPhone/> (+351) 215 894 595</p>
            <p className="contact-item"><FaPhone/> (+351) 215 894 595</p>
          </div>
          <div className="col-md-4 offset-md-1">
            <h6><FormattedMessage id="contacts.send_message" />:</h6>
            <form>
              <p>
              <span className="radio-check-option">
                <label for="option-server"><FormattedMessage id="forms.server" /></label>
                <input type="radio" id="option-server" value="server" name="product"/>
              </span>
                <span className="radio-check-option">
                <label for="option-vm"><FormattedMessage id="forms.vm" /></label>
                  <input type="radio" id="option-vm" value="vm" name="product"/>
              </span>
              <span className="radio-check-option">
                <label for="option-cloud"><FormattedMessage id="forms.hosting" /></label>
                  <input type="radio" id="option-cloud" value="cloud" name="product"/>
              </span>
              </p>
              <input type="text" placeholder={intl.formatMessage({ id: "forms.name" })} />
              <input type="text" placeholder={intl.formatMessage({ id: "forms.email" })} />
              <input type="text" placeholder={intl.formatMessage({ id: "forms.phone" })} />
              <textarea placeholder={intl.formatMessage({ id: "forms.message" })}></textarea>
              <button className="vbtn vbtn--gradient"><FormattedMessage id="buttons.send" /></button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contacts